import React from 'react'
import { graphql } from 'gatsby'
import 'twin.macro'

import SEO from '../components/SEO'
import ArticleListPage from '../components/ArticleListPage'

const Index = ({ data }) => {
  return (
    <>
      <SEO />
      <ArticleListPage data={data} />
    </>
  )
}
export default Index

export const query = graphql`
  query {
    allMdx(limit: 10, sort: { fields: frontmatter___published, order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            slug
            published
            summary
            thumbnail {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          excerpt(pruneLength: 250)
        }
      }
    }
    banner: file(relativePath: { glob: "banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
